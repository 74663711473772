// NOTE: when/if this wizard form becomes default, the functions in
// leadform-multistep.js might have to migrate into this file

// Import wizard leadform from Frontend Engine
import 'vt-frontend-engine/app/scripts/components/leadform-wizard';

// JS for Ecom-specific variations
(function() {
  // Add a class to main that dims the background behind the form
  const main = document.querySelector('main.multistep-main-content');
  const wizardLeadforms = document.querySelectorAll('.js-leadform--mini');
  for (let i = wizardLeadforms.length - 1; i >= 0; i--) {
    // checks for the name panel to complete (currently the first panel)
    // event sent in leadform-wizard.js in FEE
    wizardLeadforms[i].addEventListener('mslf.name-panel.complete', function() {
      main.classList.add('multistep-main-content--dim-background');
    });
  }
})();
