// Import multistep leadform from Frontend Engine
import 'vt-frontend-engine/app/scripts/components/leadform-multistep';
import _matchViewport from 'vt-frontend-engine/app/scripts/principles/responsive/match-viewport';

// JS for Ecom-specific variations
(function() {
  const breakpoint = 'width-xxl';
  var multistepLeadforms = document.querySelectorAll('.contact-form--multistep');

  for (var i = multistepLeadforms.length - 1; i >= 0; i--) {
    var leadform = multistepLeadforms[i];
    leadform.addEventListener('mslf.panel1.complete', function() {
      // Custom event for Google Tag Manager to track progress through the steps of the form
      // Low-speed way to prevent this from firing in dev since GTM isn't loaded/available
      if (window.dataLayer) {
        dataLayer.push({ event: 'gtm-mslf-step-1-complete' });
      }

      // On small screens, scroll to top of form on step 2
      if (!_matchViewport(breakpoint)) {
        $('html, body').animate(
          {
            scrollTop: $(leadform).offset().top - 80
          },
          200
        );
      }
    });
  }
})();
