// Form JS that is used on almost every page across the application

require('../components/_leadform-multistep.js');
require('../components/_leadform-horizontal.js');
require('../components/_leadform-wizard.js');
import * as eventTracking from '../components/_leadform-events.js';

document.addEventListener('DOMContentLoaded', function() {
  eventTracking.track();
});
