// Component: Leadform Horizontal

(function() {
  var horizontalLeadform = document.querySelector('.js-horizontal-leadform');

  // Focus on the first field of the horizontal leadform if something has scrolled the page to it
  if (horizontalLeadform) horizontalLeadform.addEventListener('scrollTo.complete', function() {
    $(horizontalLeadform).find('input[name="full_name"]').focus();
  });
})();
