import { Interaction } from 'llt-action-tracker';
let interaction;

export function getInteraction() {
  if(!interaction) {
    const defaults = {
      action_tracker: {
        endpoint: `https://${window.config.apiHost}/v2/pages/interaction`,
        application_id: 'components'
      }
    };

    const params = window.components || defaults;

    interaction = new Interaction({
      endpoint: params.action_tracker.endpoint,
      applicationId: params.action_tracker.application_id
    });
  }

  return interaction;
}


/**
 * Send event to New Relic
 *
 * @param   {string}           The target element
 * @param   {string}  leadFormVersion  The lead form version
 *
 */
export function sendToNewRelic(selector, action) {
  const data = {
    target: selector,
    visitorId: visitorId(),
    leadformVersion: leadformVersion()
  };

  window.newrelic && window.newrelic.addPageAction(action, data);
}

/**
 * Send event to action tracker
 *
 * @param   {string}  action           Lead form action
 * @param   {string}  leadFormVersion  Lead form version
 *
 * @return  {[type]}                   [return description]
 */
export function sendToActionTracker(action) {
  const label = action.replace(/\./g, '_');
  const data = {
    target: label,
    dataAttributes: {
      leadformVersion: leadformVersion(),
      leadformAction: action
    },
    category: 'leadform-panel',
    label: label
  };

  return getInteraction().track('lead-form', data);
}

export function sendEvent(selector, action, sendActionTracker = false) {
  sendToNewRelic(selector, action);

  if (sendActionTracker) {
    sendToActionTracker(action);
  }
}

/**
 * Gets the visitor id of a user from meta tag
 * --
 */
function visitorId() {
  var visitorIdTag = document.head.querySelector('meta[name=visitor-id]');
  return visitorIdTag && visitorIdTag.content;
}

function leadformVersion() {
  const versionField = document.querySelector('[name="leadform"]');
  return (versionField && versionField.value) || '';
}

function setEventListener(cssClass) {
  let target = document.querySelector(cssClass);

  if (target)
    target.addEventListener('click', function() {
      sendEvent(cssClass, 'form-submit');
    });
}

function trackButtonEvents() {
  let buttonClasses = [
    '.gtm-body-contact-submit-button',
    '.gtm-footer-contact-submit-button',
    '.gtm-bottom-contact-form-submit',
    '.gtm-top-contact-form-submit',
    '.tr-lead-constructor-submit'
  ];

  for (let i = buttonClasses.length - 1; i >= 0; i--) {
    setEventListener(buttonClasses[i]);
  }
}

function trackContinueButton() {
  // Track MSLF Continue Button
  let multistepLeadforms = document.querySelectorAll('.contact-form--multistep');

  if (multistepLeadforms) {
    for (let i = multistepLeadforms.length - 1; i >= 0; i--) {
      multistepLeadforms[i].addEventListener('mslf.panel1.complete', function() {
        sendEvent('.js-multistep-next-btn', 'mslf-continue');
      });
    }
  }
}

function trackMiniSubmitButton() {
  // Track top/mini MSLF Submit Button
  let target = document.querySelector('.gtm-mslf-top-submit');

  if (target) {
    target.addEventListener('click', function() {
      sendEvent('.gtm-mslf-top-submit', 'mslf-submit');
    });
  }
}

function trackWizardEvents() {
  // Track each wizard panel as it is completed
  // These events come from the wizard leadform js in FEE
  let mslfWizardEvents = [
    'mslf.name-panel.complete',
    'mslf.zip-panel.complete',
    'mslf.email-panel.complete',
    'mslf.phone-panel.complete',
    'mslf.name-zip-panel.complete',
    'mslf.email-phone-panel.complete',
    'mslf.welcome.complete',
    'mslf.who-needs-tutor.complete',
    'mslf.grade-level.complete',
    'mslf.how-soon.complete',
    'mslf.what-subject.complete'
  ];

  let mslfWizards = document.querySelectorAll('.js-leadform');
  if (mslfWizards) {
    for (let i = mslfWizards.length - 1; i >= 0; i--) {
      mslfWizardEvents.forEach(function(eventName) {
        mslfWizards[i].addEventListener(eventName, function() {
          sendEvent('.tr-mslf-panel', eventName, true);
        });
      });
    }
  }
}

function trackGetStarted() {
  // Track Get Started button clicks
  // i.e. any button that scrolls to Hagrid form
  // NOTE: each button will fire both this event and the mslf.welcome.complete panel event above
  let getStartedButtons = document.querySelectorAll('.js-get-started');
  if (getStartedButtons) {
    for (let i = getStartedButtons.length - 1; i >= 0; i--) {
      getStartedButtons[i].addEventListener('click', function() {
        let actionName;
        if ($(this).parents('.top-bar').length > 0) {
          // nav "Get Started" button
          actionName = 'nav-get-started-button-clicked';
        } else if ($(this).hasClass('js-request-button-upper')) {
          // upper tutor bio "Request A Tutor" button
          actionName = 'request-button-upper-clicked';
        } else if ($(this).hasClass('js-request-button-lower')) {
          // lower tutor bio "Request A Tutor" button
          actionName = 'request-button-lower-clicked';
        } else {
          // any other in-body get started button
          actionName = 'body-get-started-button-clicked';
        }
        sendEvent('.js-get-started', actionName);
      });
    }
  }
}

function trackInfoPrices() {
  // Track Info & Prices button clicks in nav
  let navInfoPricesButton = document.querySelector('.edge-button--info-prices.js-scroll-trigger');

  if (navInfoPricesButton) {
    navInfoPricesButton.addEventListener('click', function() {
      sendEvent('.js-info-prices', 'info-prices-button-clicked');
    });
  }

  // Track Info & Prices button clicks
  let infoPricesButtons = document.querySelectorAll('.js-info-prices');
  if (infoPricesButtons) {
    for (let i = infoPricesButtons.length - 1; i >= 0; i--) {
      infoPricesButtons[i].addEventListener('click', function() {
        sendEvent('.js-info-prices', 'body-info-prices-button-clicked');
      });
    }
  }
}

export function track() {
  trackInfoPrices();
  trackGetStarted();
  trackWizardEvents();
  trackMiniSubmitButton();
  trackContinueButton();
  trackButtonEvents();
}
